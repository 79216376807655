import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import Icon from "../custom-widgets/icon";

/**
 * NavDataObject
 * @typedef {Object} NavDataObject
 * @property {string} id - required id from the service card we want to scroll to.
 * @property {string} name - required string.
 */

/**
 * This is the component to render the service navigation to the related service card.
 * 
 * @param {Object} props - The object containing props.
 * @param {NavDataObject[]} props.navData - Array of links to go to the related service card.
 *   - `id`: required id from the service card we want to scroll to.
 *   - `name`: required string.
 * @param {string} props.className - Optional classes for div container for the <ul> element.
 * @returns {React.JSX.Element} Returns the JSX component to render.
 */
const ServiceNavigation = ({
  // Same names as in BusinessServicesCards.js
  navData = [
    {
      name: "Payment Processing Services",
      id: "payment-processing-services"
    },
    {
      name: "HR and Payroll Services",
      id: "hr-and-payroll-services"
    },
    {
      name: "Point of Sale and Inventory Systems",
      id: "point-of-sale-and-inventory-systems"
    },
    {
      name: "Customer Engagement",
      id: "customer-engagement"
    },
    {
      name: "CollectEarly&trade;",
      id: "collectearly"
    },
    {
      name: "Cash Flow Management",
      id: "cash-flow-management"
    },
    {
      name: "Accounting Automation",
      id: "accounting-automation"
    },
    {
      name: "WaFd Bank Work Perks",
      id: "wafd-bank-work-perks"
    }
  ],
  className = ""
}) => {
  return (
    <div className={className}>
      <ul className="list-unstyled">
        {navData.map(({ name, id }, index) => {
          return (
            <li key={index}>
              <Link to={`#${id}-service-anchor`} id={`${id}-service-anchor-link`} className="text-decoration-none">
                <Icon name="arrow-down" class="mr-2 mt-1" />
                <span className="d-inline" dangerouslySetInnerHTML={{ __html: name }} />
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ServiceNavigation;

ServiceNavigation.propTypes = {
  navData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  className: PropTypes.string
};
